import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.regexp.match.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import PanelButton from '~/components/panels/PanelButton.vue';
import Card from '~/components/cards/Card.vue';
import MenuPanelButton from '~/components/nav/MenuPanelButton.vue';
import { useMenuPanelStore } from '~/stores/menuPanel';
import cpMenu from '~/utilities/cpMenu';

// This dont require reactivity and additional memmory usage
var menuPanels = {
  'mm-panel-0': {
    index: 1
  },
  'mm-panel-1': {
    index: 2
  },
  'mm-panel-2': {
    index: 3
  },
  'mm-panel-3': {
    index: 4
  },
  'mm-panel-4': {
    index: 5
  }
};
export default {
  components: {
    PanelButton: PanelButton,
    AddToCart: function AddToCart() {
      return import('./cart/AddToCart.vue');
    },
    Subscribe: function Subscribe() {
      return import('~/components/cart/Subscribe.vue');
    },
    SubWidget: function SubWidget() {
      return import('./widgets/SubscriptionStatus.vue');
    },
    Card: Card,
    MenuPanel: function MenuPanel() {
      return import('~/components/nav/MenuPanel.vue');
    },
    MenuPanelButton: MenuPanelButton,
    DualityMenuItem: function DualityMenuItem() {
      return import('~/components/nav/DualityMenuItem.vue');
    },
    DualityMenuButton: function DualityMenuButton() {
      return import('~/components/nav/DualityMenuButton.vue');
    }
  },
  data: function data() {
    return {
      saleUrl: '/creativepractice/christmas-specials',
      saleStart: new Date('2021-12-12T22:00:00.000Z'),
      saleEnd: new Date('2022-01-07T22:00:00.000Z'),
      // activeMenu: 'student',
      routesToHide: ['creativepractice-course-slug', 'creativepractice-course-course-module-module', 'creativepractice-course-course-module-module-test', 'creativepractice-course-course-module-module-article', 'creativepractice-results-id', 'creativepractice-marathon',
      // Conferences
      'conference-cases-smpr-2020', 'conference-cpractice-2020', 'conference-cases-product-design-2021', 'conference-cases-marketing-strategy-pr-2021', 'conference-dysarium',
      // Standalone pages
      'cases-design-challenge', 'cases-special-event'],
      panelTransitionDirection: 'bottom',
      isPanelOpening: false,
      isPanelClosing: false
    };
  },
  computed: {
    isSaleActive: function isSaleActive() {
      var now = new Date();
      return now >= this.saleStart && now <= this.saleEnd;
    },
    isExpert: function isExpert() {
      return this.$auth.user && this.$auth.user.coursesExpert && this.$auth.user.coursesExpert.length;
    },
    isHr: function isHr() {
      return this.$auth.user && (this.$auth.user.role.fullAccess || this.$auth.user.hrCompanyAccess && this.$auth.user.hrCompanyAccess.length);
    },
    isStakeholder: function isStakeholder() {
      return this.$auth.user && this.$auth.user.role.stakeholder;
    },
    isPartner: function isPartner() {
      return this.$auth.user && this.$auth.user.partnership;
    },
    isRegularUser: function isRegularUser() {
      return !(this.isHr || this.isExpert || this.isStakeholder || this.isPartner);
    },
    hideMenu: function hideMenu() {
      var routeName = this.$route.name;
      if (!routeName) return false;
      var match = function match(r) {
        return routeName.match(r + '___');
      };
      return this.routesToHide.some(match);
    },
    minimizeMenu: function minimizeMenu() {
      var route = this.$route;
      var name = route.name;
      if (!name) return false;
      return [/^creativepractice-course-course/, [/^creativepractice-course-slug/, 'slug']].some(function (params) {
        var regex = Array.isArray(params) ? params[0] : params;
        var paramKey = Array.isArray(params) ? params[1] || 'course' : 'course';
        if (!regex.test(name)) return false;
        return paramKey in (route === null || route === void 0 ? void 0 : route.params) && route.params[paramKey] == 'creativity-beyond-borders';
      });
    },
    menuPanelStore: function menuPanelStore() {
      return useMenuPanelStore();
    },
    activeMenuPanel: function activeMenuPanel() {
      return this.menuPanelStore.getActive('cp-menu-panel');
    },
    isPanelHidden: function isPanelHidden() {
      return this.menuPanelStore.getHidden('cp-menu-panel');
    },
    menuItems: function menuItems() {
      var languages = this.$cookieStore.contentLocale.split('.');
      var menu = cpMenu.filter(function (o) {
        return o.locale ? languages.includes(o.locale) : true;
      });
      return menu;
    },
    authPanelStore: function authPanelStore() {
      return useAuthPanelStore();
    }
  },
  watch: {
    activeMenuPanel: function activeMenuPanel(newVal, oldVal) {
      if (!menuPanels[newVal] || !menuPanels[oldVal]) return 'bottom';
      if (newVal != oldVal) {
        this.panelTransitionDirection = menuPanels[newVal].index > menuPanels[oldVal].index ? 'right' : 'left';
      }
    }
  },
  methods: {
    register: function register() {
      this.authPanelStore.$patch({
        direction: 'left',
        state: 'register'
      });
    },
    login: function login() {
      this.authPanelStore.$patch({
        direction: 'right',
        state: 'login'
      });
    },
    menuItemShown: function menuItemShown(item, screen) {
      // General hide property
      if (item.hide) return false;

      // Screen limiter
      if (item.screens && !item.screens.includes(screen)) return false;

      // Date limiters
      if (!item.showStart && !item.showEnd && !item.hideStart && !item.hideEnd) return true;
      var now = new Date();
      var showStart = item.showStart && new Date(item.showStart);
      var showEnd = item.showEnd && new Date(item.showEnd);
      var hideStart = item.hideStart && new Date(item.hideStart);
      var hideEnd = item.hideEnd && new Date(item.hideEnd);
      if (showStart && now < showStart) return false;
      if (showEnd && now >= showEnd) return false;
      if (hideStart && hideEnd) {
        return now < hideStart || now >= hideEnd;
      } else if (hideStart) {
        return now < hideStart;
      } else if (hideEnd) {
        return now >= hideEnd;
      }
      return true;
    },
    menuItemLabel: function menuItemLabel(item) {
      var label = item.label;
      if (item.panel == 'prices' && item.sale) {
        var now = new Date();
        var saleStart = item.sale.start && new Date(item.sale.start);
        var saleEnd = item.sale.end && new Date(item.sale.end);
        if (now >= saleStart && now < saleEnd) label = item.saleLabel;
      }
      return this.$t(label);
    },
    menuItemSublabel: function menuItemSublabel(item) {
      var label = item.sublabel;
      if (typeof label != 'string' && label.label) {
        var count = label.count || item.items.length;
        return this.$tc(label.label, count);
      }
      return this.$t(label);
    },
    priceMenuPanelSaleActive: function priceMenuPanelSaleActive(panel) {
      if (panel.panel == 'prices' && panel.sale) {
        var now = new Date();
        var saleStart = panel.sale.start && new Date(panel.sale.start);
        var saleEnd = panel.sale.end && new Date(panel.sale.end);
        if (now >= saleStart && now < saleEnd) return true;
      }
      return false;
    }
  }
};