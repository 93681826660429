import Content from '~/components/CreativePracticeContent.vue';
import CpMenu from '~/components/CreativePracticeMenu.vue';
export default {
  components: {
    Content: Content,
    CpMenu: CpMenu,
    Toasts: function Toasts() {
      return import('~/components/toasts/Toasts.vue');
    },
    FeedSocket: function FeedSocket() {
      return import('~/components/feed/Socket.vue');
    }
  },
  head: function head() {
    var description = this.$t('head.creativepractice.description');
    var title = this.$t('head.creativepractice.title');
    var image = "".concat(process.env.baseUrl, "/images/creative-practice/og-image.png");
    var url = "".concat(process.env.baseUrl).concat(this.$nuxt.$route.path);
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
        'data-theme': this.theme
      },
      title: title,
      meta: [{
        charset: 'utf-8'
      }, {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1, viewport-fit=cover'
      }, {
        name: "google-site-verification",
        content: "veOw5u6tiz_rjuCgicnUAcNCIVI5CBBKhemR7-eyK7E"
      }, {
        hid: "apple-mobile-web-app-title",
        name: "apple-mobile-web-app-title",
        content: "Creative Practice"
      },
      // Primary Meta Tags
      {
        hid: 'title',
        name: "title",
        content: title,
        property: "og:title"
      }, {
        hid: 'description',
        name: "description",
        content: description,
        property: "og:description"
      }, {
        hid: 'description',
        name: "image",
        content: image,
        property: "og:image"
      },
      // Open Graph / Facebook
      {
        property: "og:site_name",
        content: "Creative Practice"
      }, {
        hid: "og:type",
        property: "og:type",
        content: "website"
      }, {
        hid: "og:url",
        property: "og:url",
        content: url
      }, {
        hid: "og:title",
        property: "og:title",
        content: title
      }, {
        hid: "og:description",
        property: "og:description",
        content: description
      }, {
        hid: "og:image",
        property: "og:image",
        content: image
      },
      // Twitter
      {
        hid: "twitter:card",
        name: "twitter:card",
        content: "summary_large_image"
      }, {
        hid: "twitter:url",
        name: "twitter:url",
        content: url
      }, {
        hid: "twitter:title",
        name: "twitter:title",
        content: title
      }, {
        hid: "twitter:description",
        name: "twitter:description",
        content: description
      }, {
        hid: "twitter:image",
        name: "twitter:image",
        content: image
      }],
      link: [
      // Icons
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: '/static/cp/favicon.ico'
      }, {
        rel: "apple-touch-icon",
        sizes: "180x180",
        href: "/static/cp/apple-touch-icon.png",
        hid: "apple-touch-icon"
      }, {
        rel: "icon",
        type: "image/png",
        sizes: "32x32",
        href: "/static/cp/favicon-32x32.png"
      }, {
        rel: "icon",
        type: "image/png",
        sizes: "16x16",
        href: "/static/cp/favicon-16x16.png"
      }, {
        rel: "mask-icon",
        href: "/static/cp/safari-pinned-tab.svg",
        color: "#ffffff"
      }, {
        rel: "preload",
        href: "/fonts/cases-iconsv1.woff?v=se8fj70",
        as: "font",
        crossorigin: "anonymous"
      }, {
        rel: "dns-prefetch",
        href: "https://connect.facebook.net/"
      }, {
        rel: "dns-prefetch",
        href: this.$CDN
      }, {
        rel: "manifest",
        href: "/static/cp/manifest.json",
        hid: "manifest"
      }]
    };
  },
  data: function data() {
    return {
      isOnline: true
    };
  },
  computed: {
    reportCardStore: function reportCardStore() {
      return useReportCardStore();
    },
    timeStore: function timeStore() {
      return useTimeStore();
    },
    subscriptionStore: function subscriptionStore() {
      return useSubscriptionStore();
    },
    appStore: function appStore() {
      return useAppStore();
    },
    theme: function theme() {
      return this.$cookieStore.theme;
    },
    user: function user() {
      return this.$auth.user;
    }
  },
  watch: {
    user: function user(val) {
      if (val) {
        this.subscriptionStore.hydrate();
        this.reportCardStore.hydrate();
      }
    }
  },
  created: function created() {
    if (!this.subscriptionStore.hydrated) this.subscriptionStore.hydrate();
    if (!this.reportCardStore.hydrated) this.reportCardStore.hydrate();
  },
  beforeMount: function beforeMount() {
    var _this = this;
    // socket Listeners
    if (process.server) return true;
    this.timeStore.start();
    this.$socket.on('connect', function () {
      _this.isOnline = true;
      if (_this.$auth.loggedIn) {
        // Subscribe to user changes
        _this.$socket.get('/user/selfSubscribe');
      }
      _this.$socket.get('/user/checkVersion', function (version) {
        if (!_this.appStore.version) {
          _this.appStore.setState({
            key: 'version',
            value: version
          });
        }
        if (_this.appStore.version !== version) {
          _this.$toastStore.add({
            id: 'newVersion',
            title: _this.$t('toast.newVersionTitle'),
            text: _this.$t('toast.newVersionText'),
            autoClose: false,
            closable: true,
            reload: true,
            image: '/images/system/person-info.png'
          });
        }
      });
      _this.$toastStore.remove('offline');
    });
    this.$socket.on('disconnect', function () {
      _this.isOnline = false;

      // Wait 5 sec and show offline notification
      setTimeout(function () {
        if (_this.isOnline) return true;
        _this.$toastStore.add({
          id: 'offline',
          title: _this.$t('toast.offlineTitle'),
          text: _this.$t('toast.offlineText'),
          autoClose: false,
          closable: false,
          icon: 'icon-ic-nosignal'
        });
      }, 5000);
    });

    // Dev tool
    this.$socket.on('tease', function (msg) {
      alert(msg.text);
    });
    if (process.client) {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }
      this.appStore.setState({
        key: 'os',
        value: os
      });
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    window.addEventListener('keydown', this.shortcuts);
    window.addEventListener('resize', this.$cropContent);
    window.addEventListener('resize', this.setVh);

    // Update accesses in real time
    this.$socket.on('newAccessToken', function () {
      _this2.subscriptionStore.hydrate();
    });
    this.setVh();
    this.isSafari();
    if (this.$route.hash) {
      this.$nextTick(function () {
        _this2.$scrollTo(_this2.$route.hash, 0);
      });
    }
    if (this.$auth.user) {
      this.$socket.on('updateUser', function (params) {
        var user = Object.assign({}, _this2.$auth.user);
        for (var key in params) {
          user[key] = params[key];
        }
        _this2.$auth.setUser(user);
      });
    }
  },
  methods: {
    shortcuts: function shortcuts(e) {
      var inputs = ['INPUT', 'TEXTAREA'];
      if (inputs.indexOf(e.target.tagName.toUpperCase()) >= 0) return true;
      if (e.target.contentEditable == 'true') return true;

      // Single key shortcuts
      if (!e.metaKey && !e.ctrlKey && !e.altKey) {
        // ESC (Close last modal)
        if (e.keyCode === 27) this.$modalityStore.closeLast();

        // M (toggle menu)
        if (e.keyCode === 77) {
          this.$modalityStore.toggleDropdown({
            type: 'dropdown',
            name: 'cp-menu'
          });
        }

        // C (toggle admin menu)
        if (e.keyCode === 67 && this.$auth.user && this.$auth.user.role && this.$auth.user.role.fullAccess) {
          this.$modalityStore.toggleDropdown({
            type: 'dropdown',
            name: 'admin-menu'
          });
        }

        // U (toggle user menu)
        if (e.keyCode === 85 && this.$auth.user) {
          this.$modalityStore.toggleDropdown({
            type: 'dropdown',
            name: 'user-menu'
          });
        }

        // L (toggle language menu)
        if (e.keyCode === 76) {
          this.$modalityStore.toggleDropdown({
            type: 'dropdown',
            name: 'locale-toggle'
          });
        }

        // T (toggle theme menu)
        if (e.keyCode === 84) {
          this.$modalityStore.toggleDropdown({
            type: 'dropdown',
            name: 'theme-toggle'
          });
        }

        // Backspace
        if (e.keyCode == 8) {
          e.preventDefault();
          if (e.shiftKey) this.$router.forward();else this.$router.back();
        }
      }
      if (e.keyCode == 82 && e.altKey) {
        e.preventDefault();
        this.$nuxt.refresh();
      }
    },
    setVh: function setVh() {
      if (process.server) return true;
      var vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', "".concat(vh, "px"));
    },
    isSafari: function isSafari() {
      if (process.server) return true;
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari) document.body.classList.add('safari');
    }
  },
  destroyed: function destroyed() {
    this.$socket.off('newAccessToken');
    window.removeEventListener('keydown', this.shortcuts);
    window.removeEventListener('resize', this.$cropContent);
  }
};