import "core-js/modules/es6.regexp.match.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import Card from '~/components/cards/Card.vue';
import NavigationMenu from './NavigationMenu.vue';
import Footer from '~/components/CasesFooterMain.vue';
import DdButton from '~/components/DdButton.vue';
import WebpPicture from '~/components/WebpPicture.vue';
import Cookie from '~/components/Cookie.vue';
var WorkHourWeekdays = ["Mon", "Tue", "Wed", "Thu", "Fri"];
var WorkHourLastWeekday = WorkHourWeekdays[WorkHourWeekdays.length - 1];

/**
 * @callback MatchCallback
 * @param {any} item
 */
/**
 * @param {string} routeName
 * @param {Dictionary<string>} routeParams
 * @returns {MatchCallback}
 */
var routeMatch = function routeMatch(routeName, routeParams) {
  /**
   * @param {string|{
   *     name: string,
   *     params: Object.<string, string|string[]>
   * }} r
   * @returns {boolean}
   */
  return function (r) {
    if (typeof r == 'string') return routeName.match(r + '___');
    if (!r.name) return false;
    var matchName = r.name;
    var matchParams = r.params;
    if (!routeName.match(matchName + '___')) return false;
    if (!matchParams) return true;
    for (var param in matchParams) {
      if (!routeParams[param]) return false;
      var value = matchParams[param];
      var paramValue = routeParams[param];
      if (!Array.isArray(value)) {
        if (value != paramValue) return false;
      } else if (!value.includes(paramValue)) return false;
    }
    return true;
  };
};
export default {
  name: 'CreativePracticeContent',
  components: {
    Card: Card,
    NavigationMenu: NavigationMenu,
    Footer: Footer,
    DdButton: DdButton,
    WebpPicture: WebpPicture,
    Cookie: Cookie,
    Hint: function Hint() {
      return import('~/components/hints/Hint.vue');
    },
    DrawerTint: function DrawerTint() {
      return import('./DrawerTint.vue');
    },
    DropdownTint: function DropdownTint() {
      return import('./DropdownTint.vue');
    },
    CasesPanel: function CasesPanel() {
      return import('./panels/CasesPanel.vue');
    },
    CasesDropdown: function CasesDropdown() {
      return import('./CasesDropdown.vue');
    },
    AuthPanel: function AuthPanel() {
      return import('./panels/Auth.vue');
    },
    UsersList: function UsersList() {
      return import('./panels/UsersList.vue');
    },
    Gains: function Gains() {
      return import('~/components/Gains.vue');
    },
    Banner: function Banner() {
      return import('~/components/banners/Trial.vue');
    },
    Sale: function Sale() {
      return import('~/components/banners/Sale.vue');
    },
    Incubator: function Incubator() {
      return import('~/components/banners/Incubator.vue');
    },
    PriceChange: function PriceChange() {
      return import('~/components/banners/PriceChange.vue');
    },
    Cart: function Cart() {
      return import('~/components/cart/Cart.vue');
    },
    Subscription: function Subscription() {
      return import('~/components/cart/Subscription.vue');
    },
    ProfileInformer: function ProfileInformer() {
      return import('~/components/profile/Informer.vue');
    },
    TrialPanel: function TrialPanel() {
      return import('~/components/panels/Trial.vue');
    },
    CookieSettings: function CookieSettings() {
      return import('~/components/panels/CookieSettings');
    }
  },
  data: function data() {
    return {
      saleStart: new Date('2024-09-24T21:00:00.000Z'),
      // saleStart: false,
      saleEnd: new Date('2024-10-07T20:59:59.000Z'),
      incubatorEnd: new Date('2024-05-31T20:59:59.000Z'),
      showBanner: false,
      showSale: false,
      showIncubator: false,
      showPriceChange: false,
      sendPulseElement: false
    };
  },
  beforeCreate: function beforeCreate() {
    this.routesToHideSupport = ['creativepractice-marathon', {
      name: 'creativepractice-course-course-about',
      params: {
        course: ['ux-ui-advanced-curated', 'smm-strategy-design-content']
      }
    }];
    this.routesToShowSendPulseChat = [{
      name: 'creativepractice-course-course-about',
      params: {
        course: ['ux-ui-advanced-curated', 'smm-strategy-design-content']
      }
    }];
    this.wdFormatter = new Intl.DateTimeFormat("en-US", {
      weekday: "short",
      timeZone: "Europe/Kiev"
    });
    this.hourFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      hour12: false,
      timeZone: "Europe/Kiev"
    });
  },
  computed: {
    usersListStore: function usersListStore() {
      return useUsersListStore();
    },
    subscriptionStore: function subscriptionStore() {
      return useSubscriptionStore();
    },
    hasSubscription: function hasSubscription() {
      return this.subscriptionStore.token;
    },
    trialAvailable: function trialAvailable() {
      return this.subscriptionStore.trialVisible;
    },
    now: function now() {
      return useTimeStore().now;
    },
    locale: function locale() {
      return this.$i18n.locale;
    },
    supportImageSrc: function supportImageSrc() {
      var filename = "support-widget-userpic-".concat(this.supportState);
      if (this.supportState == 'weekend') {
        filename = "".concat(filename, "-").concat(this.locale);
      }
      return "https://cdn.cases.media/image/original/".concat(filename, ".png");
    },
    isArticlePage: function isArticlePage() {
      var _this = this;
      if (!this.$route.name) return true;
      return ["creativepractice-article-slug", "module-article"].some(function (s) {
        return _this.$route.name.indexOf(s) >= 0;
      });
    },
    isSupportActive: function isSupportActive() {
      return this.supportState == 'active';
    },
    supportState: function supportState() {
      var wd = this.wdFormatter.format(this.now);
      var hour = this.hourFormatter.format(this.now);
      var state = 'active';
      if (WorkHourWeekdays.includes(wd)) {
        // Weekday
        if (hour < 6 || hour >= 24) {
          // 24 is actually 0:00
          state = "night";
        } else if (hour < 10) {
          state = "morning";
        } else if (hour >= 19) {
          if (wd == WorkHourLastWeekday) state = "weekend";else state = "night";
        } else {
          state = "active";
        }
      } else {
        // Weekend
        state = "weekend";
      }
      return state;
    },
    hideSupport: function hideSupport() {
      var routeName = this.$route.name;
      var routeParams = this.$route.params;
      if (!routeName) return false;
      var match = routeMatch(routeName, routeParams);
      return this.routesToHideSupport.some(match);
    },
    showSendPulseChat: function showSendPulseChat() {
      var routeName = this.$route.name;
      var routeParams = this.$route.params;
      if (!routeName) return false;
      var match = routeMatch(routeName, routeParams);
      return this.routesToShowSendPulseChat.some(match);
    },
    cookieConsentVisible: function cookieConsentVisible() {
      return this.$cookieStore.cookieConsentVisible;
    }
  },
  watch: {
    // Show banner in 10 sec after cookie consent closed
    cookieConsentVisible: function cookieConsentVisible(val, oldVal) {
      var _this2 = this;
      if (oldVal && !val) {
        setTimeout(function () {
          _this2.pickBanner();
        }, 10000);
      }
    },
    $route: function $route() {
      if (process.browser) {
        this.injectSendPulseChat();
      }
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    // Show banner in 5 sec if cookie consent already accespted
    if (!this.cookieConsentVisible) {
      setTimeout(function () {
        _this3.pickBanner();
      }, 5000);
    }
    if (process.browser) {
      this.injectSendPulseChat();
    }
  },
  methods: {
    pickBanner: function pickBanner() {
      var now = new Date();
      var priceChangeEnd = new Date('2024-02-29T22:00:00.000Z');
      var saleStarted = !this.saleStart || now >= this.saleStart;
      var saleEnded = now >= this.saleEnd;
      var priceChanged = now >= priceChangeEnd;
      var incubatorEnded = now >= this.incubatorEnd;
      if (saleStarted && !saleEnded && this.$cookieStore.isUkrainian) {
        this.showSale = true;
      } else if (!incubatorEnded) {
        this.showIncubator = true;
      } else if (!this.hasSubscription && this.trialAvailable) {
        this.showBanner = true;
      } else if (!priceChanged) {
        this.showPriceChange = true;
      }
    },
    injectSendPulseChat: function injectSendPulseChat() {
      var findSendPulseElement = function findSendPulseElement() {
        return document.querySelector('sp-live-chat');
      };
      if (!this.showSendPulseChat) {
        if (!this.sendPulseElement) return;
        if (this.sendPulseElement === true) {
          var el = findSendPulseElement();
          if (!el) return; // Not loaded yet, skip

          this.sendPulseElement = el;
        }
        this.sendPulseElement.classList.add('hidden');
        return;
      }
      if (!this.sendPulseElement) {
        var script = document.createElement("script");
        script.src = 'https://cdn.pulse.is/livechat/loader.js';
        script.async = true;
        script.setAttribute('data-live-chat-id', '6686c82e030c581f1008f4e6');
        document.body.append(script);
        var _el = findSendPulseElement();
        this.sendPulseElement = _el || true;
        return;
      }
      if (this.sendPulseElement === true) {
        var _el2 = findSendPulseElement();
        if (!_el2) return; // Not loaded yet, skip

        this.sendPulseElement = _el2;
      }
      this.sendPulseElement.classList.remove('hidden');
    }
  }
};