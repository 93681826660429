<template>
    <button
        class="menu-panel-button"
        :class="{
            active: isActive
        }"
        @touchstart="toggle()"
        @mouseenter="show()"
        @mouseleave="hide()"
    >
        <template v-if="isBtn">
            <slot />
        </template>

        <template v-else>
            <div class="menu-panel-caption">
                <slot />
            </div>
            <span class="icon-ic-arrow-simple color-gray-3 ml-h" />
        </template>
    </button>
</template>


<script>
    import { useMenuPanelStore } from '~/stores/menuPanel';


    export default {
        props: {
            panel: {
                type: String,
                required: true
            },

            item: {
                type: String,
                required: true
            },

            type: {
                type: String
            }
        },

        computed: {
            activeItem () {
                return this.menuPanelStore.getActive(this.panel);
            },

            isActive () {
                return !this.isHidden && this.activeItem === this.item;
            },

            isHidden () {
                return this.menuPanelStore.getHidden(this.panel);
            },

            isBtn () {
                return this.type && this.type === 'btn';
            },

            menuPanelStore () {
                return useMenuPanelStore();
            },
        },

        methods: {
            show () {
                this.menuPanelStore.show(this.panel, this.item);
            },

            hide () {
                this.menuPanelStore.hide(this.panel);
            },

            toggle () {
                this.menuPanelStore.toggle(this.panel, this.item);
            }
        }
    }
</script>


<style lang="scss" scoped>
    .menu-panel-button {
        display:     inline-flex;
        flex-flow:   row nowrap;
        align-items: center;

        position: relative;

        @media screen and (min-width: 1200px) {
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                border-radius: 2px;
                background-color: transparent;
                transition: background-color .1s ease;
            }
        }

        &.active:after,
        &.nuxt-link-active:after {
            background-color: var(--color-cases-blue);
        }
    }
</style>
