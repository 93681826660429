import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import DdButton from './DdButton.vue';
import PanelButton from './panels/PanelButton.vue';
import WebpPicture from '~/components/WebpPicture.vue';
import cpMenu from '~/utilities/cpMenu';
export default {
  name: 'CasesMenu',
  components: {
    DdButton: DdButton,
    PanelButton: PanelButton,
    WebpPicture: WebpPicture,
    CasesDropdown: function CasesDropdown() {
      return import('./CasesDropdown.vue');
    },
    Collapse: function Collapse() {
      return import('~/components/collapse/Collapse.vue');
    },
    CollapseButton: function CollapseButton() {
      return import('~/components/collapse/Button.vue');
    },
    Checkbox: function Checkbox() {
      return import('~/components/form/Checkbox.vue');
    },
    SubWidget: function SubWidget() {
      return import('./widgets/SubscriptionStatus.vue');
    },
    LanguageSettings: function LanguageSettings() {
      return import('~/components/editor/LanguageSettings.vue');
    }
  },
  data: function data() {
    return {
      saleUrl: '/creativepractice/christmas-specials',
      saleStart: new Date('2021-12-12T22:00:00.000Z'),
      saleEnd: new Date('2022-01-07T22:00:00.000Z'),
      modalType: 'menu',
      modalName: 'main',
      closingClassName: 'cases-menu-closing'
    };
  },
  computed: {
    appStore: function appStore() {
      return useAppStore();
    },
    isReviewer: function isReviewer() {
      var role = this.$auth.user && this.$auth.user.role;
      var roles = ['Reviewer'];
      return role && roles.includes(role.name.en);
    },
    isModer: function isModer() {
      var role = this.$auth.user && this.$auth.user.role;
      var roles = ['Administrator', 'Creative Practice Administrator', 'Creative Practice TeamLead'];
      return role && roles.includes(role.name.en);
    },
    isSaleActive: function isSaleActive() {
      var now = new Date();
      return now >= this.saleStart && now <= this.saleEnd;
    },
    locale: function locale() {
      return this.$i18n.locale;
    },
    reportCardStore: function reportCardStore() {
      return useReportCardStore();
    },
    hasCertificate: function hasCertificate() {
      return this.reportCardStore.hasCertificate;
    },
    isAdmin: function isAdmin() {
      return this.$auth.user && this.$auth.user.role && this.$auth.user.role.fullAccess;
    },
    cartStore: function cartStore() {
      return useCartStore();
    },
    cartEmpty: function cartEmpty() {
      return this.cartStore.isEmpty;
    },
    menuItems: function menuItems() {
      var languages = this.$cookieStore.contentLocale.split('.');
      var menu = cpMenu.filter(function (o) {
        return o.locale ? languages.includes(o.locale) : true;
      });
      return menu;
    }
  },
  watch: {
    locale: function locale(newVal) {
      this.$gtag('set', 'user_properties', {
        interface_locale: newVal
      });
    }
  },
  mounted: function mounted() {
    this.$gtag('set', 'user_properties', {
      color_mode: this.defineUserTheme(),
      interface_locale: this.locale
    });
  },
  methods: {
    openCart: function openCart(index) {
      this.$modalityStore.closeDropdown({
        name: 'cart-menu'
      });
      this.cartStore.setActiveCartIndex(index);
      this.$modalityStore.openModal({
        type: 'cart',
        name: 'def'
      });
    },
    openLanguageMenu: function openLanguageMenu() {
      var _this = this;
      this.$modalityStore.closeDropdown({
        name: 'user-menu'
      });
      setTimeout(function () {
        _this.$modalityStore.openDropdown({
          name: 'locale-toggle'
        });
      }, 100);
    },
    setTheme: function setTheme(val) {
      this.$cookieStore.setState("theme", val);
      this.$gtag('set', 'user_properties', {
        color_mode: this.defineUserTheme()
      });
    },
    menuItemShown: function menuItemShown(item, screen) {
      // General hide property
      if (item.hide) return false;

      // Screen limiter
      if (item.screens && !item.screens.includes(screen)) return false;

      // Date limiters
      if (!item.showStart && !item.showEnd && !item.hideStart && !item.hideEnd) return true;
      var now = new Date();
      var showStart = item.showStart && new Date(item.showStart);
      var showEnd = item.showEnd && new Date(item.showEnd);
      var hideStart = item.hideStart && new Date(item.hideStart);
      var hideEnd = item.hideEnd && new Date(item.hideEnd);
      if (showStart && now < showStart) return false;
      if (showEnd && now >= showEnd) return false;
      if (hideStart && hideEnd) {
        return now < hideStart || now >= hideEnd;
      } else if (hideStart) {
        return now < hideStart;
      } else if (hideEnd) {
        return now >= hideEnd;
      }
      return true;
    },
    menuItemLabel: function menuItemLabel(item) {
      var label = item.label;
      if (item.panel == 'prices' && item.sale) {
        var now = new Date();
        var saleStart = item.sale.start && new Date(item.sale.start);
        var saleEnd = item.sale.end && new Date(item.sale.end);
        if (now >= saleStart && now < saleEnd) label = item.saleLabel;
      }
      return this.$t(label);
    },
    defineUserTheme: function defineUserTheme() {
      var theme = this.$cookieStore.theme;
      var GA_mode = 'Light';
      if (!['light', 'dark'].includes(theme)) {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          theme = 'dark';
        } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
          theme = 'light';
        }
      }
      if (theme == 'light') GA_mode = 'Light';
      if (theme == 'dark') GA_mode = 'Dark';
      this.appStore.$patch({
        colorMode: theme
      });
      return GA_mode;
    }
  }
};