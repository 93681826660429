const cpMenu = [
    {
        label:    'menu.courses',
        sublabel: 'menu.coursesSub',
        btnLabel: 'menu.allCourses',
        href:     '/creativepractice/courses',

        _secondaryBtnLabel: 'menu.maps',
        _secondaryHref:     '/creativepractice/maps',
        _secondaryNew:      true,

        _panel:    'duality-links',
        _items: [
            {
                label:    'menu.curated',
                hide:     true,
                sublabel: {
                    label: 'menu.courseCount'
                },
                btnLabel: 'menu.allCatCourses',
                href:     '/creativepractice/courses?type=curated',
                items: [

                ]
            },
            {
                label:    'menu.design',
                sublabel: {
                    label: 'menu.courseCount'
                },
                btnLabel: 'menu.allCatCourses',
                _href:     '/creativepractice/courses?topic=design',
                items: [
                    {
                        label: 'menu.course.designBasics',
                        icon:  '/images/creative-practice/menu/icon-course-design.png',
                        href:  '/creativepractice/course/design-basics/about'
                    }, {
                        label: 'menu.course.designComp',
                        icon:  '/images/creative-practice/menu/icon-course-composition.png',
                        href:  '/creativepractice/course/design-basics-composition/about'
                    }, {
                        label: 'menu.course.typographyDesign',
                        icon:  '/images/creative-practice/menu/icon-typography-design.svg',
                        href:  '/creativepractice/course/typography-design/about'
                    }, {
                        label: 'menu.course.drawingTabletBasics',
                        icon:  '/images/creative-practice/drawing-tablet-basics/icon.png',
                        href:  '/creativepractice/course/drawing-tablet-basics/about'
                    }, {
                        label: 'menu.course.webdesignBasics',
                        icon:  '/images/creative-practice/menu/icon-course-webdesign.svg',
                        href:  '/creativepractice/course/webdesign-basics/about'
                    }, {
                        label: 'menu.course.uiDesignBasics',
                        icon:  '/images/creative-practice/menu/icon-course-ux.svg',
                        href:  '/creativepractice/course/ux-ui-design-basics/about'
                    }, {
                        label: 'menu.course.layoutPrototype',
                        icon: "/images/creative-practice/menu/icon-course-layouts-interactive-prototypes.svg",
                        href:  '/creativepractice/course/layouts-interactive-prototypes/about'
                    },  {
                        label: 'menu.course.uxResearch',
                        icon:  '/images/creative-practice/menu/icon-course-ux-research.png',
                        href:  '/creativepractice/course/ux-research/about'
                    }, {
                        label: 'menu.course.userPersona',
                        icon:  '/images/creative-practice/menu/icon-course-persona-card.png',
                        href:  '/creativepractice/course/user-persona/about'
                    }, {
                        label: 'menu.course.cjm',
                        icon:  '/images/creative-practice/menu/icon-course-cjm.svg',
                        href:  '/creativepractice/course/cjm/about'
                    }
                ]
            }, {
                label:    'menu.instrumental',
                sublabel: {
                    label: 'menu.courseCount'
                },
                btnLabel: 'menu.allCatCourses',
                href:     '/creativepractice/courses/instrumental',
                items:    [
                    {
                        label: 'menu.course.figmaEssentials',
                        icon:  '/images/creative-practice/menu/icon-course-figma-essentials.svg',
                        href:  '/creativepractice/course/figma-essentials/about'
                    }, {
                        label: 'menu.course.figmaText',
                        icon:  '/images/creative-practice/menu/icon-course-figma-text.svg',
                        href:  '/creativepractice/course/figma-text/about'
                    }, {
                        label: 'menu.course.figmaAutoLayouts',
                        icon:  '/images/creative-practice/menu/icon-course-figma-auto-layouts.svg',
                        href:  '/creativepractice/course/figma-auto-layouts/about'
                    }, {
                        label: 'menu.course.figmaDesignSystem',
                        icon:  '/images/creative-practice/menu/icon-course-figma-design-system.png',
                        href:  '/creativepractice/course/figma-design-system/about'
                    }, {
                        label: 'menu.course.psBasics',
                        icon:  '/images/creative-practice/menu/icon-course-ps.svg',
                        href:  '/creativepractice/course/photoshop-basics/about'
                    }, {
                        label: 'menu.course.psAdvanced',
                        icon:  '/images/creative-practice/menu/icon-course-ps2.svg',
                        href:  '/creativepractice/course/photoshop-advanced/about'
                    }, {
                        label: 'menu.course.fireflyBasics',
                        icon:  '/images/creative-practice/menu/icon-course-firefly-basics.png',
                        href:  '/creativepractice/course/firefly-basics/about'
                    }, {
                        label: 'menu.course.aeBasics',
                        icon:  '/images/creative-practice/menu/icon-course-ae.svg',
                        href:  '/creativepractice/course/after-effects-basics/about'
                    }, {
                        label: 'menu.course.aiBasics',
                        icon:  '/images/creative-practice/menu/icon-course-ai.svg',
                        href:  '/creativepractice/course/illustrator-basics/about'
                    }, {
                        label: 'menu.course.indesignBasics',
                        icon:  '/images/creative-practice/menu/icon-course-id.svg',
                        href:  '/creativepractice/course/indesign-basics/about'
                    }, {
                        label: 'menu.course.neuralnetBasics',
                        icon:  '/images/creative-practice/menu/icon-course-neuralnet-3.png',
                        href:  '/creativepractice/course/neuralnet-basics/about'
                    }, {
                        label: 'menu.course.blenderIntensive',
                        icon:  '/images/creative-practice/menu/icon-course-blender-intensive-workshop.png',
                        href:  '/creativepractice/course/blender-intensive-workshop/about'
                    },{
                        label: 'menu.course.cinema4dBasics',
                        icon:  '/images/creative-practice/menu/icon-course-cinema4d.png',
                        href:  '/creativepractice/course/cinema4d-basics/about'
                    }, {
                        label: 'menu.course.webflowBasics',
                        icon:  '/images/creative-practice/menu/icon-course-webflow.svg',
                        href:  '/creativepractice/course/webflow-basics/about'
                    }, {
                        label: 'menu.course.webflowAnimations',
                        icon:  '/images/creative-practice/menu/icon-course-webflow-animations.png',
                        href:  '/creativepractice/course/webflow-animations/about'
                    }
                ]
            }, {
                label:    'menu.startups',
                sublabel: {
                    label: 'menu.courseCount'
                },
                btnLabel: 'menu.allCatCourses',
                href:     '/creativepractice/courses/enterpreneurship',
                items:    [
                    {
                        label: 'menu.course.portfolio',
                        icon:  '/images/creative-practice/portfolio/icon.png',
                        href:  '/creativepractice/course/portfolio/about'
                    }, {
                        label: 'menu.course.casesCreation',
                        icon:  '/images/creative-practice/menu/icon-course-cases.png',
                        href:  '/creativepractice/course/cases-creation/about'
                    }, {
                        label: 'menu.course.creativeMoney',
                        hide:  true,
                        icon:  '/images/creative-practice/creative-money/icon.png',
                        href:  '/creativepractice/course/creative-money/about'
                    }, {
                        label: 'menu.course.fop',
                        icon:  'https://cdn.cases.media/image/original/course-icon-fop.png',
                        href:  '/creativepractice/course/fop/about'
                    }, {
                        label: 'menu.course.copyright',
                        icon:  '/images/creative-practice/menu/icon-course-copyright.png',
                        href:  '/creativepractice/course/copyright/about'
                    }, {
                        label: 'menu.course.usfGrants',
                        icon:  '/images/creative-practice/menu/icon-course-usf.svg',
                        href:  '/creativepractice/course/usf-grants/about'
                    }, {
                        label: 'menu.course.creativeEurope',
                        icon:  '/images/creative-practice/menu/icon-course-creativeeurope.svg',
                        href:  '/creativepractice/course/creative-europe/about'
                    }, {
                        label: 'menu.course.beyondBorders',
                        icon:  '/images/creative-practice/menu/icon-course-creativity-beyond-borders.png',
                        href:  '/creativepractice/course/creativity-beyond-borders/about'
                    },
                ]
            }, {
                label:    'menu.smm',
                sublabel: {
                    label: 'menu.courseCount'
                },
                btnLabel: 'menu.allCatCourses',
                href:     '/creativepractice/courses/smm',
                items:    [
                    {
                        label: 'menu.course.smmBasics',
                        icon:  '/images/creative-practice/menu/icon-course-smm-basics.png',
                        href:  '/creativepractice/course/smm-basics/about'
                    }, {
                        label: 'menu.course.smmStrategy',
                        icon:  '/images/creative-practice/menu/icon-course-smm-strategy.png',
                        href:  '/creativepractice/course/smm-strategy/about'
                    }, {
                        label: 'menu.course.smmManagement',
                        icon:  '/images/creative-practice/menu/icon-course-smm-management.png',
                        href:  '/creativepractice/course/smm-management/about'
                    }, {
                        label: 'menu.course.kukSmmConcept',
                        icon:  '/images/creative-practice/menu/icon-course-kukurudza-1.png',
                        href:  '/creativepractice/course/kukurudza-smm-concept/about'
                    },
                    {
                        label: 'menu.course.kukSmmFigma',
                        icon:  '/images/creative-practice/menu/icon-course-kukurudza-2.png',
                        href:  '/creativepractice/course/kukurudza-smm-figma/about'
                    },
                    {
                        label: 'menu.course.kukSmmDesign',
                        icon:  '/images/creative-practice/menu/icon-course-kukurudza-3.png',
                        href:  '/creativepractice/course/kukurudza-smm-design/about'
                    },
                    {
                        label: 'menu.course.kukSmmSystem',
                        icon:  '/images/creative-practice/menu/icon-course-kukurudza-4.png',
                        href:  '/creativepractice/course/kukurudza-smm-system/about'
                    },
                    {
                        label: 'menu.course.kukSmmPortfolio',
                        icon:  '/images/creative-practice/menu/icon-course-kukurudza-5.png',
                        href:  '/creativepractice/course/kukurudza-smm-portfolio/about'
                    }
                ]
            }, {
                label:    'menu.frontend',
                sublabel: {
                    label: 'menu.courseCount'
                },
                btnLabel: 'menu.allCatCourses',
                href:     '/creativepractice/courses/frontend',
                items:    [
                    {
                        label: 'menu.course.frontendBasics',
                        icon:  '/images/creative-practice/menu/icon-course-frontend.svg',
                        href:  '/creativepractice/course/frontend-basics/about'
                    }, {
                        label: 'menu.course.javascriptBasics',
                        icon:  '/images/creative-practice/menu/icon-course-js.svg',
                        href:  '/creativepractice/course/javascript-basics/about'
                    }
                ]
            }, {
                label:    'menu.productManagement',
                sublabel: {
                    label: 'menu.courseCount'
                },
                btnLabel: 'menu.allCatCourses',
                href:     '/creativepractice/courses/product-management',
                items: [
                    {
                        label: 'menu.course.productManagementResearch',
                        icon:  '/images/creative-practice/menu/icon-course-asta-school-1.svg',
                        href:  '/creativepractice/course/product-management-research/about'
                    }, {
                        label: 'menu.course.productManagementCreation',
                        icon:  '/images/creative-practice/menu/icon-course-asta-school-2.svg',
                        href:  '/creativepractice/course/product-management-creation/about'
                    }, {
                        label: 'menu.course.productManagementImprovement',
                        icon:  '/images/creative-practice/menu/icon-course-asta-school-3.svg',
                        href:  '/creativepractice/course/product-management-improvement/about'
                    }
                ]
            }, {
                label:    'menu.webflowUniversity',
                sublabel: {
                    label: 'menu.courseCount'
                },
                btnLabel: 'menu.allCatCourses',
                href:     '/creativepractice/courses?school=webflow-university',
                items:    [
                    {
                        label: 'menu.course.webflow101',
                        icon:  '/images/creative-practice/webflow-101-crash-course/icon.svg',
                        href:  '/creativepractice/course/webflow-101-crash-course/about'
                    },
                    {
                        label: 'menu.course.figmaToWebflow',
                        icon:  '/images/creative-practice/figma-to-webflow/icon.png',
                        href:  '/creativepractice/course/figma-to-webflow/about'
                    }
                ]
            }
        ]
    }, {
        label:      'menu.sale',
        class:      'fall-sale',
        screens:    ['mobile'],
        locale:     'uk',
        href:       '/creativepractice/sale/autumn-sale-2024?utm_source=creative-practice&utm_medium=cp-menu&utm_campaign=autumn-sale-2024',
        showStart:  '2024-09-24T21:00:00.000Z',
        showEnd:    '2024-10-07T20:59:59.000Z',
    }, {
        label:    'menu.sale70',
        class:    'sale-easter-deals',
        screens:  ['mobile'],
        href:     '/creativepractice/sale/easter-deals?utm_source=creative-practice&utm_medium=cp-menu&utm_campaign=easter-deals',
        // showStart: '2024-04-21T21:00:00.000Z',
        showEnd:  '2024-05-06T20:59:59.000Z',
    }, {
        label:     'menu.saleUp',
        class:     'sale-summer-sale',
        screens:   ['mobile'],
        href:      '/creativepractice/sale/summer-sale?utm_source=creative-practice&utm_medium=cp-menu&utm_campaign=summer-sale',
        showStart: '2024-07-24T21:00:00.000Z',
        showEnd:   '2024-08-05T20:59:59.000Z',
    }, {
        label:    'menu.other',
        sublabel: 'menu.otherSub',
        panel:    'duality-links',
        items: [
            {
                label:        'menu.lectures',
                sublabel:     'menu.lecturesSub',
                dualityLabel: 'menu.topics',
                btnLabel:     'menu.allLectures',
                href:         '/creativepractice/lectures',
                items: [
                    {
                        label: 'menu.lectureTopic.webdesign',
                        href:  '/creativepractice/lectures?topic=webdesign'
                    }, {
                        label: 'menu.lectureTopic.smm',
                        href:  '/creativepractice/lectures?topic=smm'
                    }, {
                        label: 'menu.lectureTopic.illustration',
                        href:  '/creativepractice/lectures?topic=illustration'
                    }, {
                        label: 'menu.lectureTopic.management',
                        href:  '/creativepractice/lectures?topic=management'
                    }, {
                        label: 'menu.lectureTopic.graphicDesign',
                        href:  '/creativepractice/lectures?topic=graphic-design'
                    }, {
                        label: 'menu.lectureTopic.uxUi',
                        href:  '/creativepractice/lectures?topic=ux-ui'
                    }
                ]
            }, {
                label:        'menu.masterClasses',
                sublabel:     'menu.masterClassesSub',
                dualityLabel: 'menu.topics',
                btnLabel:     'menu.allMasterClasses',
                href:         '/creativepractice/masterclasses',
                items: [
                    {
                        label: 'menu.mcTopic.webdesign',
                        href:  '/creativepractice/masterclasses?topic=webdesign'
                    }, {
                        label: 'menu.mcTopic.graphicDesign',
                        href:  '/creativepractice/masterclasses?topic=graphic-design'
                    }, {
                        label: 'menu.mcTopic.uxUi',
                        href:  '/creativepractice/masterclasses?topic=ux-ui'
                    }
                ]
            }, {
                label:    'menu.conferences',
                sublabel: 'menu.conferencesSub',
                btnLabel: 'menu.allEvents',
                href:     '/creativepractice/events',
                screens:  ['desktop'],
                items: [
                    {
                        label: 'menu.conference.dysarium',
                        href: '/conference/dysarium'
                    },
                    {
                        label: 'menu.conference.marathon2022',
                        href: '/creativepractice/marathon'
                    },
                    {
                        label: 'CASES: Product Design 2021',
                        href:  '/conference/cases-product-design-2021'
                    },
                    {
                        label: 'CASES: Marketing, Strategy & PR',
                        href:  '/conference/cases-marketing-strategy-pr-2021'
                    }
                    // {
                    //     label: 'CASES: Product Design 2022',
                    //     href:  '/conference/cases-product-design-2022'
                    // }
                ]
            }, {
                label:   'menu.events',
                href:    '/creativepractice/events',
                screens: ['mobile', 'tablet']
            }
        ]
    }, {
        label:      'menu.prices',
        sublabel:   'menu.pricesSub',
        panel:      'prices',
        btnLabel:   'menu.moreAboutPricing',
        btnScreens: ['desktop'],
        saleLabel:  'menu.pricesAndSpecials',
        href:       '/creativepractice/subscription',
        // items: [
        //     {
        //         label: 'menu.aboutPricing',
        //         href:  '/creativepractice/subscription',
        //         screens: ['mobile', 'tablet']
        //     }
        // ],
        // lists: [
        //     {
        //         label: 'menu.subscriptions',
        //         items: [
        //             {
        //                 label:  'menu.subscription.monthly',
        //                 price:  550,
        //                 period: 'monthly',
        //                 slug:   'creative-practice-subscription-1-month',
        //                 icon:   '/images/creative-practice/subscription/mobile-monthly.png'
        //             }, {
        //                 label:  'menu.subscription.yearly',
        //                 price:  5520,
        //                 period: 'yearly',
        //                 slug:   'creative-practice',
        //                 icon:   '/images/creative-practice/subscription/mobile-yearly.png'
        //             }
        //         ]
        //     }, {
        //         label: 'menu.certificates',
        //         items: [
        //             {
        //                 label:      'menu.certificate.m3',
        //                 price:      1560,
        //                 salePrice:  1250,
        //                 saleBadge:  '-20%',
        //                 access:     '6101170ec90aff6d4fe9577d',
        //                 saleAccess: '620515a15e758d845dcc291a',
        //                 icon:       '/images/creative-practice/subscription/mobile-3m.png'
        //             }, {
        //                 label:      'menu.certificate.m6',
        //                 price:      2940,
        //                 salePrice:  2350,
        //                 saleBadge:  '-20%',
        //                 access:     '6101170ec90aff6d4fe9577e',
        //                 saleAccess: '620515a15e758d845dcc291d',
        //                 icon:       '/images/creative-practice/subscription/mobile-6m.png'
        //             }
        //         ]
        //     }
        // ],
        // sale: {
        //     label: 'menu.special.val2022',
        //     href:  '/creativepractice/design-lovers',
        //     icon:  '/images/creative-practice/val-2022/og-2.png',
        //     start: '2022-02-09T22:00:00.000Z',
        //     end:   '2022-02-16T22:00:00.000Z'
        // }
    }, {
        label:      'menu.journal',
        sublabel:   'menu.journalSub',
        panel:      'journal',
        btnLabel:   'menu.allArticles',
        btnScreens: ['desktop'],
        href:       '/creativepractice/articles',
        items: [
            {
                label: 'menu.designArticles',
                href:  '/creativepractice/articles?topic=composition&topic=color&topic=typography&topic=webdesign&topic=graphic-design'
            }, {
                label: 'menu.inspirationArticles',
                href:  '/creativepractice/articles?topic=literature&topic=research&topic=design-thinking&topic=design-process'
            }
        ],
        lists: [
            {
                label: 'menu.designArticles',
                items: [
                    {
                        label: 'menu.articleTopic.composition',
                        href:  '/creativepractice/articles?topic=composition'
                    }, {
                        label: 'menu.articleTopic.color',
                        href:  '/creativepractice/articles?topic=color'
                    }, {
                        label: 'menu.articleTopic.typography',
                        href:  '/creativepractice/articles?topic=typography'
                    }, {
                        label: 'menu.articleTopic.webdesign',
                        href:  '/creativepractice/articles?topic=webdesign'
                    }, {
                        label: 'menu.articleTopic.graphicDesign',
                        href:  '/creativepractice/articles?topic=graphic-design'
                    }
                ]
            }, {
                label: 'menu.inspiration',
                items: [
                    {
                        label: 'menu.articleTopic.literature',
                        href:  '/creativepractice/articles?topic=literature'
                    }, {
                        label: 'menu.articleTopic.research',
                        href:  '/creativepractice/articles?topic=research'
                    }, {
                        label: 'menu.articleTopic.designThinking',
                        href:  '/creativepractice/articles?topic=design-thinking'
                    }, {
                        label: 'menu.articleTopic.designProcess',
                        href:  '/creativepractice/articles?topic=design-process'
                    }
                ]
            }
        ],
        podcast: {
            label: 'menu.podcast',
            icon:  '/images/creative-practice/menu/podcast-2.jpeg',
            links: [

                {
                    label: 'Apple Podcasts',
                    icon:  '/images/creative-practice/icons-common/apple-podcasts.svg',
                    type:  'external',
                    href:  'https://podcasts.apple.com/ua/podcast/%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%BD%D0%B0-%D0%BF%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B0-%D0%BF%D1%80%D0%BE-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%BD%D1%96-%D0%BF%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B8/id1561209814'
                }, {
                    label: 'Google Podcasts',
                    icon:  '/images/creative-practice/icons-common/google-podcasts.svg',
                    type:  'external',
                    href:  'https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5zb3VuZGNsb3VkLmNvbS91c2Vycy9zb3VuZGNsb3VkOnVzZXJzOjkyNDE4NjQ0NS9zb3VuZHMucnNz?sa=X&ved=0CAQQ9sEGahcKEwjgy8ah9rCAAxUAAAAAHQAAAAAQAQ'
                }, {
                    label: 'Megogo',
                    icon:  '/images/creative-practice/icons-common/megogo.svg',
                    type:  'external',
                    href:  'https://megogo.page.link/hkKY86tXWruDDvyw7'
                }, {
                    label: 'NV Подкасти',
                    icon:  '/images/creative-practice/icons-common/nv.svg',
                    type:  'external',
                    href:  'https://podcasts.nv.ua/podcast/68-kreatyvna-practyka-pro-kreatyvni-practyky.html'
                }, {
                    label: 'Spotify',
                    icon:  '/images/creative-practice/icons-common/spotify.svg',
                    type:  'external',
                    href:  'https://open.spotify.com/show/3Ug3H7Du6IF80zcGS91SgA?si=89c9cc49ccbe4ae0'
                },
            ]
        }
    }, {
        label:    'menu.about',
        hide:     true,
        sublabel: 'menu.aboutSub',
        panel:    'contacts',
        lists: [
            {
                label: 'menu.aboutMain',
                items: [
                    {
                        label: 'menu.staticPage.about',
                        href:  '/creativepractice?noredirect=1'
                    }, {
                        label: 'menu.staticPage.business',
                        hide:  true,
                        href:  '/business'
                    }, {
                        label: 'menu.staticPage.team',
                        hide:  true,
                        href:  '/creativepractice/about'
                    }, {
                        label: 'menu.staticPage.jobs',
                        hide:  true,
                        href:  '/vacancies'
                    }, {
                        label: 'menu.staticPage.brandbook',
                        href:  '/creativepractice/brandbook'
                    }, {
                        label: 'menu.staticPage.telegram',
                        href:  'https://t.me/+sTtdQzIJXQFkZDYy',
                        type:  'external'
                    },{
                        label: 'menu.staticPage.discord',
                        href:  'https://discord.gg/fya7YhXfqk',
                        type:  'external'
                    }, {
                        label: 'menu.staticPage.cookie',
                        href:  '/cookie'
                    }
                ]
            }
        ],
        items: [
            {
                label: 'menu.staticPage.about',
                href:  '/creativepractice?noredirect=1'
            }, {
                label: 'menu.staticPage.business',
                hide:  true,
                href:  '/business'
            }, {
                label: 'menu.staticPage.team',
                hide:  true,
                href:  '/creativepractice/about'
            }, {
                label: 'menu.staticPage.jobs',
                hide:  true,
                href:  '/vacancies'
            }, {
                label: 'menu.staticPage.brandbook',
                href:  '/creativepractice/brandbook'
            }, {
                label: 'menu.staticPage.discord',
                href:  'https://discord.gg/fya7YhXfqk',
                type:  'external'
            }, {
                label: 'menu.staticPage.cookie',
                href:  '/cookie'
            }
        ],
        contacts: {
            label:      'menu.contacts',
            "-phone":      '+380 68 702 01 82',
            phoneLabel: 'menu.businessHours',
            email:      'team@creativepractice.com.ua',
            links: [
                {
                    label: 'Facebook',
                    type:  'external',
                    href:  'https://www.facebook.com/creativepractice.com.ua',
                    icon:  'icon-ic-facebook-circle'
                }, {
                    label: 'Instagram',
                    type:  'external',
                    href:  'https://www.instagram.com/creativepractice.com.ua',
                    icon:  'icon-ic-instagram'
                }, {
                    label: 'CASES',
                    href:  '/creative_practice',
                    icon:  'icon-pacman'
                }, {
                    label: 'Telegram',
                    type:  'external',
                    href:  'https://t.me/creativepracticeua',
                    icon:  'icon-ic-telegram-logo'
                }, {
                    label: 'YouTube',
                    type:  'external',
                    href:  'https://www.youtube.com/c/CreativePractice',
                    icon:  'icon-ic-play-button'
                }, {
                    label: 'SoundCloud',
                    hide:  true,
                    type:  'external',
                    href:  'https://soundcloud.com/creativepracticeua',
                    icon:  'icon-ic-soundcloud'
                }, {
                    label: 'LinkedIn',
                    type:  'external',
                    href:  'https://www.linkedin.com/school/creativepractice',
                    icon:  'icon-ic-linkedin'
                }
            ]
        }
    }, {
        locale: 'uk',
        label: 'menu.incubator',
        type:  'external',
        href:  'https://incubator.cases.media',
    }, {
        label:      'menu.sale',
        class:      'fall-sale',
        screens:    ['tablet', 'desktop'],
        locale:     'uk',
        href:       '/creativepractice/sale/autumn-sale-2024?utm_source=creative-practice&utm_medium=cp-menu&utm_campaign=autumn-sale-2024',
        showStart:  '2024-09-24T21:00:00.000Z',
        showEnd:    '2024-10-07T20:59:59.000Z',
    }, {
        label:    'menu.sale70',
        class:    'sale-easter-deals',
        screens:  ['tablet', 'desktop'],
        href:     '/creativepractice/sale/easter-deals?utm_source=creative-practice&utm_medium=cp-menu&utm_campaign=easter-deals',
        // showStart: '2024-04-21T21:00:00.000Z',
        showEnd:  '2024-05-06T20:59:59.000Z',
    }, {
        label:     'menu.saleUp',
        class:     'sale-summer-sale',
        screens:   ['tablet', 'desktop'],
        href:      '/creativepractice/sale/summer-sale?utm_source=creative-practice&utm_medium=cp-menu&utm_campaign=summer-sale',
        showStart: '2024-07-24T21:00:00.000Z',
        showEnd:   '2024-08-05T20:59:59.000Z',
    },
]


module.exports = cpMenu;
